<template>
  <div>
    <v-container fluid>
      <div class="main">

        <v-row v-if="!isLoading && !stores.length" justify="center" align="center">
          <v-col cols="12" lg="6">
            <v-card class="shadow">
              <v-card-text class="text-center pa-8">
                <v-icon size="80" color="orange">mdi-alert-circle-outline</v-icon>
                <div class="mt-3">
                  <h3>Aucune boutique disponible</h3>
                  <p class="mt-2">Veuillez ajouter au moins une boutique pour commencer !</p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="isLoading">
          <v-col cols="12" lg="4" md="4" v-for="i in 6" :key="i">
            <v-card class="shadow">
              <v-card-text>
                <v-skeleton-loader type="avatar,text,text"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" v-else>
          <v-col cols="12" lg="4" md="4" v-for="(item,i) in stores" :key="i">
            <v-card class="shadow custom-border-bottom"
                    :to="'/partner-vouchers/store/' + item.id">
              <v-card-text>
                <div class="d-flex align-center justify-space-between">
                  <div class="font-weight-medium grey--text text--darken-3">
                    {{ item.name }}
                  </div>
                  <div>
                    <v-avatar size="55" v-if="item.account && item.account.photo">
                      <v-img :src="fileUrl+item.account.photo"></v-img>
                    </v-avatar>

                    <v-avatar size="55" v-else color="gifty">
                      <v-icon size="25" dark>mdi-store</v-icon>
                    </v-avatar>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      stores: [],
      isLoading: false,
      fileUrl: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    getStores() {
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers/stores').then(res => {
        this.isLoading = false
        this.stores = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getStores()
  }
}
</script>

<style scoped>
.custom-border-bottom::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #d60c56;
  margin: 0 auto;
  border-radius: 5px;
}
</style>